import { Controller } from "stimulus"

export default class extends Controller {
  get_selected_numbers(event) {
    const checkboxes = document.querySelectorAll('input[type="checkbox"][name^="selected"]');
    const selectedNumbers = [];

    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        const number = checkbox.name.match(/\d+/)[0];
        selectedNumbers.push(number);
      }
    });

    return selectedNumbers;
  }

  approve(event) {
    $.ajax({
      type: 'POST',
      url: '/admin/instructors/batch_approve',
      data: { ids: this.get_selected_numbers() },
      dataType: 'json',
      scriptType: 'script',
      success: function(response) {
        window.location.href = "/admin/instructors";
      },
      error: function(error) {
        console.log(error);
      }
    });
  }

  unapprove() {
    $.ajax({
      type: 'POST',
      url: '/admin/instructors/batch_unapprove',
      data: { ids: this.get_selected_numbers() },
      dataType: 'json',
      scriptType: 'script',
      success: function(response) {
        window.location.href = "/admin/instructors";
      },
      error: function(error) {
        console.log(error);
      }
    });
  }
}
