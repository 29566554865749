/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import '@babel/polyfill';
import 'whatwg-fetch';
import 'stylesheets/custom'

import Turbolinks from 'turbolinks';

import 'dirrty/dist/jquery.dirrty';
import 'selectize/dist/js/selectize';

global.Rails = require("@rails/ujs");

global.Rails.start();
Turbolinks.start();

import "controllers"

$(document).on('turbolinks:load', function(){

  $(function(){
    $('.certificate').click(function(){

      $(this).toggleClass('min');
      $(this).toggleClass('max');
    });
  });


  $("input:checkbox.indeterminate").prop('indeterminate', true)

  $('#newOtherskillFormModal').on('submit', function () {
    $('#loading-dual-ring').show();
    return true;
  });

  $('#newMyskillsForm').on('submit', function () {
    $('#loading-dual-ring').show();
    return true;
  });

  $('#selected-plays > li').addClass('horizontal');
  $('#selected-plays li:not(.horizontal)').addClass('sub-level');
  $('a[href^="http"][href*="henry"]').addClass('henrylink');

  $('#switcher').click(function(event) {
    if ($(event.target).is('button')) {
      var bodyClass = event.target.id.split('-')[1];
      $('body').removeClass().addClass(bodyClass);
      $('#switcher button').removeClass('selected');
      $(event.target).addClass('selected');
      event.stopPropagation();
    }
  });

  $('#switcher').click(function() {

      $('#switcher button').toggleClass('hidden');

  });

  $('#switcher h3').hover(function() {
    $(this).addClass('hover');
  }, function() {
    $(this).removeClass('hover');
  });

  $("#edit-instructor").dirrty();

  $("#select-instructors-project_id").selectize();
});

$(document).on('turbolinks:before-visit', function(){
  // debugger;
  if($("#edit-instructor").dirrty("isDirty")) {
    return confirm("有修改没有保存，确定要离开页面？");
  }
  return true;
});
