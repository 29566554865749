import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["email", "hint"]

  email_changed() {
    let that = this;
    $.ajax({
      url: '/setting/check_email',
      data: { email: this.emailTarget.value },
      global: false,
      success: function(data) {
        if (data.result) {
          that.hintTarget.innerHTML = '邮箱已经存在（已经注册），请换一个邮箱！'
        } else {
          that.hintTarget.innerHTML = ''
        }
      }
    })
  }
}
